import axios from "axios";
import router from "./router";
import Element from "element-ui"

axios.defaults.baseURL = "https://pjcs.pjcszh.cn/pjcs/api/"
// axios.defaults.baseURL="http://192.168.1.39:8080/pjcs"
const request = axios.create({
    timeout: 15000,
    headers: {
        'Content-Type': "application/json; charset=utf-8"
    }
})

request.interceptors.response.use(
    response => {
        if (response.data.code === 200 || response.data.code === 201 || response.data.code === 0){
            return response
        }else {
			return response
            // Element.Message.error(response.data.msg, {duration: 3000})
            // return Promise.reject(response.data.msg)
        }
    },
    error => {
        Element.Message.error(error.msg, {duration: 3000})
        return Promise.reject(error)
    }
)

export default request